import { BYTES_FILE_SIZE_MAP } from 'src/constants/file-sizes';

const ATTACHMENT_MAX_SIZE_MB = 4;

export const appConfig = {
    env: process.env.REACT_APP_ENV,

    sentry: {
        enabled: process.env.REACT_APP_SENTRY_ENABLED === 'true',
        dsn: process.env.REACT_APP_SENTRY_DSN,
    },

    liveChat: {
        enabled: process.env.REACT_APP_LIVE_CHAT_ENABLE === 'true',
        remoteConfig: process.env.REACT_APP_LIVE_CHAT_REMOTE_CONFIG,
    },

    ga: {
        enabled: process.env.REACT_APP_GA_ENABLE === 'true',
        id: process.env.REACT_APP_GA_ID,
    },

    firebase: {
        core: {
            apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
            authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
            projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
            storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
            messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
            appId: process.env.REACT_APP_FIREBASE_APP_ID,
            measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
        },

        messaging: {
            enabled: process.env.REACT_APP_FIREBASE_MESSAGING_ENABLED === 'true',
            senderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
            webPushCertificateKey: process.env.REACT_APP_FIREBASE_WEB_PUSH_CERTIFICATE_KEY,
        },
    },

    bookingsOverview: {
        onTrackGap: 7, // date gap that splits "upcoming" type to "onTrack" or "upcoming"
    },

    contacts: {
        customerManagement: 'mailto:customerengagement@vitastudent.com',
        callbackForm: 'https://vitastudent.as.me/schedule.php',
    },

    socialSpaces: {
        dailyLimit: 3,
        twoWeekLimit: 8,
        durationLimit: 3,
    },

    moveIn: {
        checkInFormURL:
            'https://salesforce-eu.123formbuilder.com/form-37390/?BookingId={bookingId}&AccId={accountId}&InvId={inventoryId}&SchId={paymentScheduleId}',
        checkOutFormURL: `https://salesforce-eu.123formbuilder.com/form-44426/?bkgid={bkgid}&accid={accid}`,
        calendarFromYear: 2022,
    },

    rebooking: {
        academicYear: '2025 / 26',
        transactionsURL: process.env.REACT_APP_REBOOKING_TRANSACTIONS_URL as string,
    },

    attachments: {
        maxSizeMegabytes: ATTACHMENT_MAX_SIZE_MB,
        maxSizeBytes: ATTACHMENT_MAX_SIZE_MB * BYTES_FILE_SIZE_MAP.megabyte,
        maxCount: 6,
    },

    alumni: {
        linkedinLink: 'https://www.linkedin.com/groups/14215053/',
        weixinLink: 'https://mp.weixin.qq.com/s/653hZOMrHCpxJXA6UhX9oQ',
    },

    codeOfPractice: {
        codeOfPracticeURL:
            "https://www.gov.scot/publications/letting-agent-code-practice/#:~:text=Section%2046%20of%20the%20Housing,'%20and%20landlords'%20money%3B%20" as string,
    },

    activeYear: '2023 / 24',
    upcomingYear: '2024 / 25',
    mockData: false,
};
